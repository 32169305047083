.parent {
    justify-content: start;
    align-items: center;
    width: 249px;
    display: flex;
    flex-direction: column;
    border-right: #797E8B 1px solid;
    overflow-x: hidden;
    overflow-y: auto;
}
.element{
}

.group{
    color:#D3D4DB;

    height: 40px;
    width: 249px;
    font-size: 12px;
    display: flex;
    align-content: center;
    align-items: center;
}

.groupText{
    padding-left: 40px;
    padding-right: 20px;

}