.parent{
    position: absolute;
    z-index: 9999999;
    display: flex;
    justify-content: flex-end;
    overflow-x: hidden;
    overflow-y:scroll;
    right: 0px;

}

.children{
    border-left: #797E8B 1px solid;

    width:320px;
    background-color: #3D4555;

    animation: show ease;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
}

.children[hide="true"]{
    animation: hide ease;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
}

@keyframes hide {
    0%{
        margin-right: 0%;
    }
    100%{
        margin-right: -100%;
    }
}

@keyframes show {
    0%{
        margin-right: -100%;
    }
    100%{
        margin-right: 0%;
    }
}

.button {
    color: white;
    width: 140px;
    text-align: center;
    border-radius: 10px;
    padding: 4px;
    cursor: pointer;

    border: 2px solid #f44336;
    transition: all 200ms;
}
.button:hover{
    background-color: #1D2533;
}