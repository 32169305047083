.parent {
    padding: 0px;
}
.parent[theme="dark"] {
    padding: 0px;
    background-color: #1D2533;

}

.area {
    margin: auto;
    overflow: hidden;

    -webkit-box-shadow: 0px 0px 39px -8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 39px -8px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 39px -8px rgba(0, 0, 0, 0.3);

    background-size: 40px 40px;
    border: 1px #B5B7C0 solid;
    background-position: center;

}

.area[theme="dark"] {
    -webkit-box-shadow: 0px 0px 39px -8px rgba(232, 232, 238, 0.1);
    -moz-box-shadow: 0px 0px 39px -8px rgba(232, 232, 238, 0.1);
    box-shadow: 0px 0px 39px -8px rgba(232, 232, 238, 0.1);

    background-size: 40px 40px;
    background-position: center center;
    border: 1px #3D4555 solid;
    background-color: #1D2533;
}

.childrenList {
    cursor: grab;
}